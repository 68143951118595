body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}


/* colour of the button used to view or share the video */
/* #actionButton { */
    /* background-color: #007aff !important; */
/* } */

/* size of the recorder button */
/* #recorder { */
    /* width: 7em; */
    /* height: 7em; */
/* } */

/* color of the record buttons various parts */

/* :root {
  --capture-button-color-idle: #ffffff;
  --capture-button-recording-progress: #ffffff;
  --capture-loading-secondary: #ffffff;
}
#recorder > #recorder-button {
  background-color: var(--capture-button-color-idle) !important;
}
#recorder > .progress-container .progress-track {
  stroke: var(--capture-button-color-idle) !important;
}
#recorder > .progress-container #progressBar {
  stroke: var(--capture-button-recording-progress) !important;
}
#recorder > .progress-container .loading-circle {
  stroke: var(--capture-loading-secondary) !important;
} */