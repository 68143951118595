.loading-screen-darken-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000088;
    flex-direction: column;
    pointer-events: none;
    z-index: 19;
}


.loading-screen-darken-background .title-graphic {
    width: 60%;
    padding-bottom: 2em;
}
.loading-screen-darken-background .loading-graphic {
    width: 45%;
    padding-bottom: 1.5em;
    padding-left: 6%;
}


.loading-screen-darken-background span {
    color: white;
    font-size: 1.5rem;
}

.loading-screen-darken-background .rotate {
    color: white;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    /* taken from example here https://stackoverflow.com/questions/16771225/css3-rotate-animation */
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}